.attr-container {
  position: relative;
  text-align: center;
  flex-grow: 1;
  width: 90px;

  > span {
    width: inherit;
  }

  > span:hover + .attr-info {
    display: block;
  } 

}

.attr-header {
  position: relative;
  width: 90px;
  text-align: center;
  flex-grow: 1;
  user-select: none;
  cursor: pointer;
  color: var(--font-color);


  > div {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    
  }

  > span {
    max-width: 80%;
    cursor: pointer;
  }

  > div > img {
    width: 10px !important;
    height: 10px !important;
  }
}

.attr-name {
  width: 180px;
}

.attr-last-contacted {
  width: 90px;
}

.attr-info {
  position: absolute;
  z-index: 1000;
  display: none;
  background-color: var(--primary-background-color);
  border-radius: 5px;
  border: 2px solid var(--neutral-gray);
  width: 200px;
  top: 0px;
  min-height: 65px;
  right: 100px;
  padding: 5px;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px),
       only screen and (max-height: 600px) {
  .attr-container {
    flex-grow: 0;
    > span {
      font-size: 16px;
    }
  }

  .attr-name {
    text-align: left;
    width: 150px;
  }
}

