.cc-mobile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0 15px;

  .cc-mobile-icon {
    width: min(35px, 20vw);
    height: min(35px, 20vw);

    > img {
      width: inherit;
      height: inherit;
    }
  }
}