.edit-icon-container {
  padding: 40px 0 40px;

  .edit-icon-image {
    width: 80%;
    padding: 0 20px 20px 20px;
    margin: 0 auto 0;
    border-bottom: 5px solid var(--neutral-gray);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .edit-icon-upload-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px 5px;
    box-sizing: content-box;
    border-radius: 5px;
    outline: 2px solid var(--light-gray);
    color: var(--font-color);

    > img {
      height: 30px;
    }

    &:hover {
      background-color: var(--theme-color);
      color: #fff;
      outline: none;
      cursor: pointer;
      > img {
        filter: brightness(200%);
      }
    }
  }

  .edit-icon-colours {
    width: 80%;
    margin: 0 auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;

    .edit-icon-colour {
      background: #fff;
      width: 30px;
      height: 30px;
      border-radius: 20px;
    
      &:hover {
        cursor: pointer;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .edit-icon-save {
    width: 30%;
    margin: 30px auto 0;
    background-color: var(--theme-color);
    padding: 8px;
    color: #fff;
    font-size: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }
}