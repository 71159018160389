.aid-grid {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  gap: 10px;

  
  .aid-grid-item {
    display: flex;
    width: min(150px, 40vw);
    height: min(150px, 40vw);
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 20px;
    opacity: 0.8;


    &:hover {
      opacity: 1;
      cursor: pointer;
      background-color: var(--neutral-gray);
      img {
        filter: saturate(100%);
      }
    }

    > img {
      height: 60%;
      filter: saturate(0%);
      opacity: 0.7;
    }

    > span {
      color: var(--font-color);
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aid-grid {
    flex-wrap: wrap-reverse;
  }
}

@media only screen and (max-width: 600px),
       only screen and (max-height: 600px) {
  .aid-grid-item {
    width: min(70px, 25vw) !important;
    height: min(70px, 25vw) !important;
    padding: 0 !important;

    
    > img {
      filter: saturate(100%) !important;
      opacity: 1 !important;
    }
  }
}