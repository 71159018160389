html, body {
  padding: 0;
  margin: 0;

}
.login-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #2eb865c0;

  .login-main {
    position: absolute;
    width: min(100vw, 500px);
    height: min(100vh, 600px);
    background-color: #fff;
    left: calc(50% - min(100vw, 500px)/2);
    top: calc(50% - min(100vh, 600px)/2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    
    > input {
      width: min(70%, 300px);
      box-sizing: border-box;
    }

    input[type="password"], input[type="email"] {
      box-shadow: none;
      border: 1px solid #ccc;
      padding: 10px;
      font-size: 20px;
      border-radius: 50px;
    }

    input[type="button"] {
      padding: 10px;
      font-size: 20px;
      border: none;
      border-radius: 50px;
      color: #fff;
      background-color: #0A9943;

      &:hover {
        background-color: #1ca051c0;
        cursor: pointer;
      }
    }
  }
}