.nav-bar-desktop {
  position: relative;

  background-color: var(--primary-background-color);

  a {
    text-decoration: none;
    color: var(--font-color);
  }

  .main-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .main-menu-container {
    width: 100%;
    height: calc(100vh - 230px);
    display: flex;

    > div {
      flex: 1 1 auto;
    }
  }

  .main-menu-search {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
  }

  .secondary-menu {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
  }

  .nav-icon {
    height: 18px;
    width: 18px;
    margin-left: 20px;
    margin-right: 20px;
    display: inline-block;
  }

  .nav-icon-initials {
    height: 24px;
    width: 24px;
    margin-left: 20px;
    margin-right: 20px;
    display: inline-block;
  }

  .nav-manage-account {
    margin: 0 auto 0;
    border-radius: 10px;
    width: 150px;
    text-align: center;
    padding: 5px;
    border: 2px solid var(--theme-color);
  }

  .nav-manage-account h4 {
    font-weight: 300;
    margin: 0;
    color: var(--theme-color) !important;
  }

  .nav-manage-account:hover {
    background-color: var(--theme-color);
    cursor: pointer;
  }

  .nav-manage-account:hover > h4 {
    color: #fff !important;
  }

  .nav-minimise {
    width: 20px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: calc(50% - 30px);
    cursor: pointer;
    z-index: 999;
  }

  .nav-minimise:hover > .nav-minimise img {
    opacity: 0.7 !important;
  }

  .nav-minimise img {
    width: inherit;
    height: inherit;
    opacity: 0.3;
    pointer-events: none;
  }
}

.nav-bar-desktop-min {
  position: relative;

  background-color: var(--primary-background-color);

  .nav-link,
  .nav-link-sub {
    margin-bottom: 5px;
  }

  .nav-link span {
    display: none;
  }

  .nav-link-sub {
    height: 40px !important;
    padding-left: 10px !important;
  }

  .nav-link-sub > span {
    display: none;
  }

  .active .nav-link-sub {
    padding-left: 5px !important;
  }

  a {
    text-decoration: none;
    color: var(--font-color);
  }

  .main-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .main-menu-container {
    width: 100%;
    height: calc(100vh - 130px);
    display: flex;

    > div {
      flex: 1 1 auto;
    }
  }

  .main-menu-search {
    display: none;
  }

  .secondary-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .nav-icon {
    height: 18px;
    width: 18px;
    margin-left: 5px;
    display: inline-block;
  }

  .nav-icon-initials {
    margin-left: 3px;
    display: inline-block;
  }

  .nav-dropdown-icon {
    display: none;
  }

  .nav-manage-account {
    display: none;
  }

  .nav-change-theme {
    display: none;
  }

  .nav-change-theme h4 {
    font-weight: 400;
    font-size: 20px;
  }

  .nav-minimise {
    width: 20px;
    height: 30px;
    position: absolute;
    right: 10px;
    bottom: 20px;
    cursor: pointer;
    z-index: 999;
  }

  .nav-minimise:hover > .nav-minimise img {
    opacity: 0.7 !important;
  }

  .nav-minimise img {
    width: inherit;
    height: inherit;
    opacity: 0.3;
    pointer-events: none;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.navbar-resize-bar {
  position: absolute;
  left: var(--navbar-width);
  bottom: 0;
  height: calc(100% - 10px);
  width: 20px;
  z-index: 1000;

  &:hover {
    cursor: w-resize;
    box-shadow: inset 4px 0 4px -4px rgb(160, 160, 160);
  }
}
