.cc-expanded {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--primary-background-color);
  border-bottom: 2px solid var(--neutral-gray);
  box-shadow: inset 0 10px 15px -10px var(--secondary-background-color);
  padding: 20px;
  box-sizing: border-box;
  gap: 10px;

  .cc-edit {
    right: 55px;
    top: 10px;
    padding: 0;
    border-radius: 20px;

  }

  .cc-expand {
    right: 10px;
    top: 10px;
    padding: 0;
    border-radius: 20px;

  }

  .cc-top {
    flex-grow: 1;
    width: 100%;
    padding: 0;

    .sm-container {
      padding-bottom: 10px;
    }
  }

  .cc-left {
    flex-grow: 1;
    min-width: 280px;
    padding: 0;
  }

  .cc-right {
    flex-grow: 1;
    width: 50%;
    min-width: 320px;
    height: auto;
    padding: 0 0 0 10px;


    .cp-box-container {
      margin: 0;
      width: 100%;
    }
  }
}
