.header-quick-options-container {
  position: absolute;
  right: 90px;

  .header-quick-options-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 190px;
    height: var(--header-height);
  }

  .header-quick-options-icon {
    cursor: pointer;
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;

    &:hover {
      background-color: var(--neutral-gray);
      //box-shadow: 0 3px 3px rgba(0,0,0,0.1);
    }
  }

  .header-quick-options-icon > img {
    margin-top: 10%;
    width: 80%;
    height: 80%;
  }

  .header-quick-options-profile {
    overflow: hidden;
    border-radius: 50px;
    font-size: 21px;
    font-family: "Roboto", sans-serif;
  }
}

.header-quick-options-view {
  position: absolute;
  top: var(--header-height);
  right: 100px;
  width: 400px;
  background-color: var(--primary-background-color);
  //border: 3px solid var(--secondary-background-color);
  border-radius: 15px;
  //box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  max-height: calc(97vh - var(--header-height));
  overflow-y: auto;
  z-index: 1;
}
