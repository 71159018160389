.nav-change-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px 20px 10px;
  gap: 15px;
  min-width: 200px;
}

.nav-change-theme h4 {
  font-weight: 400;
  font-size: 20px;
}
