.search-results-container {
  width: min(100%, 500px);
  margin: 0 auto 0;
  color: var(--font-color);

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .search-result {
    display: flex;
    font-size: 22px;
    align-items: center;
    gap: 20px;
    padding: 10px 20px 10px 20px;
    background-color: var(--primary-background-color);
    margin-bottom: 5px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
    overflow: hidden;
    
    &:hover {
      background-color: var(--light-gray);
      cursor: pointer;
    }
  }

  .search-result-event {
    position: relative;
    height: 61px;
    box-sizing: border-box;

    .search-result-event-nub {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
    }

    .search-result-event-date {
      margin-left: auto;
    }
  }
}

@media only screen and (max-width: 600px), only screen and (max-height: 600px){
  .search-results-container {
    padding-top: 20px;
  }
}