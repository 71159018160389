.dropdown-notifications {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;

  > h3 {
    margin-bottom: 5px;
  }

  .notification-event {
    position: relative;
    padding: 10px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--neutral-gray);
    border-radius: 5px;
    overflow: hidden;

    &:hover {
      background-color: var(--neutral-gray);
      cursor: pointer;
    }

    > div {
      position: absolute;
      width: 10px;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}