.group-header {
  width: 100%;
  background-color: var(--primary-background-color);
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 3px 5px rgba(0,0,0,0.1);
  display: flex;
  gap: 20px;
  align-items: flex-end;
  position: relative;

  input {
    font-size: 30px;
    border: none;
    background-color: var(--neutral-gray);
    border-radius: 10px;
    padding: 5px;
    font-weight: 600;
    width: calc(100% - 100px);

    &:focus {
      outline: none;
    }

    &:read-only {
      background: none;
    }
  }

  .group-header-edit-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;

    > div {
      width: 30px;
      height: 30px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      > img {
        width: inherit;
        height: inherit;
      }
    }
  }
}

@media only screen and (max-width: 600px),
       only screen and (max-height: 600px) {
  .group-header {
    flex-direction: column;
    align-items: center;
    border-radius: 0;

    input {
      max-width: 60%;
      font-size: 23px;
      text-align: center;
    }

    .profile-pic-container {
      width: 72px !important;
      height: 72px !important;
      > span {
        font-size: 54px !important;
      }
    }

    .group-header-edit-buttons {
      flex-direction: column;
      > div {
        width: 30px;
        height: 30px;
      }
    }
  }
}