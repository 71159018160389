.main-menu {
  height: auto !important;
  .nav-link {
    height: 40px;
    font-size: 20px;
    color: var(--font-color);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .nav-dropdown-icon {
    position: relative;
    margin-left: auto;
    margin-right: 30px;
    opacity: 0.3;
    width: 13px;
    height: 13px;
  }

  .nav-dropdown-icon-open {
    transform: rotate(-90deg);
  }

  .nav-dropdown-icon img {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .nav-link:hover {
    cursor: pointer;
    color: var(--theme-color);
  }

  .nav-link-sub {
    height: 40px;
    font-size: 18px;
    color: var(--font-color);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .nav-link-sub:hover {
    cursor: pointer;
    color: var(--theme-color);
  }

  .active .nav-link {
    color: var(--theme-color);
    border-left: 5px solid var(--theme-color);
    padding-left: 5px;
  }

  .active .nav-link-sub {
    color: var(--theme-color);
    border-left: 5px solid var(--theme-color);
    padding-left: 15px;
  }

  a {
    text-decoration: none;
    color: var(--font-color);
  }
}
