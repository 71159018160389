:root {
  --header-height: 70px;
  --navbar-width: 300px;
  --content-container-padding: 50px;
}

$max-mobile-height: 600px;
$max-mobile-width: 600px;

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

body {
  position: fixed;
}

.container {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: var(--primary-background-color);
  overflow: hidden;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--header-height);
  z-index: 11;
}

.nav-bar-desktop {
  position: fixed;
  left: 0;
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  width: var(--navbar-width);
}

.nav-bar-desktop-min {
  position: fixed;
  left: 0;
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  width: var(--navbar-width);
}

.content-container {
  position: relative;
  top: var(--header-height);
  left: var(--navbar-width);
  width: calc(100vw - var(--navbar-width) - 40px);
  height: calc(100% - var(--header-height));
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
  background-color: var(--secondary-background-color);
}

.inner-content-container {
  padding: var(--content-container-padding);
  padding-bottom: 0;
  min-height: calc(100% - var(--content-container-padding) * 2);
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: $max-mobile-width),
  only screen and (max-height: $max-mobile-height) {
  :root {
    --header-height: 50px;
    --navbar-width: 0px !important;
    --content-container-padding: 40px;
  }

  .content-container {
    width: calc(100vw - var(--navbar-width));
    height: calc(100vh - var(--header-height) - 50px);
    border-radius: 0;
    box-sizing: border-box;
    left: 0px;
  }

  .inner-content-container {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .nav-dropdown-icon {
    display: none;
  }
}

img {
  pointer-events: none;
}
