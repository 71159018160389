.sliding-button-container {
  position: relative;
  width: 50px;
  height: 32px;
  background-color: var(--theme-color);
  border-radius: 50px;
  cursor: pointer;
  box-shadow: inset 0px 0px 5px rgba(0,0,0,0.3);
}

.sliding-button-off {
  background-color: var(--sliding-button-background);
}

.sliding-button-notch {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: var(--primary-background-color);
  border-radius: 50px;
  top: 4px;
  box-shadow: 0 0 4px rgba(0,0,0,0.3);
}

.sliding-button-notch-left {
  left: 4px;
}

.sliding-button-notch-right {
  right: 4px;
}

@keyframes slide-left {
  0% {
    right: 4px;
  }
  100% {
    left: 4px;
  }
}

.slide-to-left {
  animation-name: slide-left;
  animation-duration: 1s;
}

@keyframes slide-right {
  0% {
    left: 4px;
  }
  100% {
    right: 4px;
  }
}

.slide-to-right {
  -webkit-animation: slide-right 5s infinite; /* Safari 4+ */
  -moz-animation: slide-right 5s infinite; /* Fx 5+ */
  -o-animation: slide-right 5s infinite; /* Opera 12+ */
  animation: slide-right 5s infinite; /* IE 10+, Fx 29+ */
}
