.dropdown-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px 0 30px;

  .dropdown-settings-nav {
    width: 100%;

    .dropdown-settings-nav-item {
      padding: 10px;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      
      > span {
        opacity: 0.8;
      }

      &:hover {
        background-color: var(--neutral-gray);

        > span {
          opacity: 1;
        }
      }
    }
  }
}