.cp-birthday-flex {
  width: var(--input-width);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;

  .cp-birthday-date {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .cp-birthday-text {
    font-size: 16px;
    font-weight: 300;
  }


  > img {
    height: 65px;
  }

  > div {
    padding: 5px;
  }
}

/*.cp-birthday img {
  height: 50px;
  margin-bottom: 10px;
}*/