.search-box-main {
  border-radius: 50px;
  border: none;
  resize: none;
  box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--secondary-background-color);
  padding: 10px 15px 10px 15px;
  color: #666;
  box-sizing: border-box;
  overflow-y: hidden;
  min-width: 200px;
}

.search-box-main:focus {
  outline: none !important;
}
