.header-desktop {
  background: var(--primary-background-color);
  color: var(--font-color);
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 30px 0 30px;
  gap: 10px;
  flex-wrap: wrap;

  #app-title {
    color: var(--theme-color);
    font-weight: 400;
    display: inline-block;
    margin: 0;
    font-size: 30px;
    width: 230px;
  }

  #logo-img {
    height: 50px;
    display: inline-block;
  }

  #app-title {
    padding-bottom: 0px;
  }
}

.header-mobile {
  background: var(--primary-background-color);
  color: var(--font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  #app-title {
    color: var(--theme-color);
    font-weight: 400;
    display: inline-block;
    margin: 0;
    font-size: 30px;
  }

  #logo-img {
    height: 40px;
    display: inline-block;
  }

  #app-title {
    padding-bottom: 0px;
  }
}
