
input[type="text"]:read-write:focus,
input[type="tel"]:focus,
input[type="email"]:focus {
  outline: none;
  box-shadow: inset 0 0 10px #8885;
}

.quick-add-container {
  box-sizing: border-box;
  padding: 30px 0 30px;

  .tags-container {
    width: 70%;
    margin: 0 auto 0;

    > .tags-search > input {
      width: 100% !important;
    }
  }

  .qa-create-another {
    margin: 10px 0 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 20px;

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }

  .qa-invalid-message {
    margin: 20px;
    p {
      margin: 5px;
      color: rgba(236, 54, 54, 0.781);
    }
  }

  h3 {
    margin: 10px 0 10px;
    font-weight: 600;
    font-size: 22px;
  }

  .quick-add-profile-pic {
    margin: 25px 0 25px;

    > div {
      margin: 0 auto 0;
    }
  }

  input {
    width: 70%;
    height: 38px;
    font-size: 20px;
    border: none;
    background-color: var(--neutral-gray);
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--font-color);
    margin-bottom: 10px;
  }

  div {
    text-align: center;
  }

  .qa-birthday {
    position: relative;
    width: 70%;
    margin: 0 auto 0;

    input:read-only {
      position: absolute;

      &:focus {
        outline: none;
      }
    }
  }

  .qa-phone-number,
  .qa-emails {
    width: 70%;
    margin: 0 auto 0;
    height: auto;

    input {
      float: left;
      width: 100%;
    }

    :last-child > input {
      width: calc(100% - 50px);
    }

    .qa-add-phone,
    .qa-add-email {
      float: right;
      width: 38px;
      height: 38px;
      background-color: var(--neutral-gray);
      border-radius: 19px;
      color: var(--font-color);
      line-height: 38px;
      font-size: 30px;
      opacity: 0.6;
    }

    .qa-add-phone:hover,
    .qa-add-phone:focus,
    .qa-add-email:hover,
    .qa-add-email:focus {
      background-color: var(--theme-color);
      color: #fff;
      cursor: pointer;
      opacity: 1;
    }
  }

  .qa-add-groups {
    width: 160px;
    margin: 30px auto 30px;
    text-align: left;

    > * {
      display: inline;
    }

    h3 {
      width: 50%;
      font-weight: 500;
      font-size: 22px;
      line-height: 40px;
    }

    .qa-add-group {
      font-size: 20px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background-color: var(--neutral-gray);
      opacity: 0.6;
      float: right;
      line-height: 40px;
      font-size: 35px;
      cursor: pointer;
      color: var(--font-color);
    }

    .qa-add-group:hover,
    .qa-add-group:focus {
      opacity: 1;
      background-color: var(--theme-color);
      color: #fff;
      outline: none;
    }
  }

  .qa-submit {
    margin: 20px 0 10px 0;
    input {
      padding-top: 6px;
      height: 50px;
      font-size: 24px;
    }

    input:hover,
    input:focus {
      background-color: var(--theme-color);
      color: #fff;
      cursor: pointer;
    }
  }
}

.qa-groups {
  width: 270px;
  margin: 20px auto 0;

  text-align: center;

  background-color: rgba(100, 100, 100, 0.1);
  border-radius: 20px;
  padding: 10px 20px 20px;
  box-sizing: border-box;

  h3 {
    margin: 10px 0 10px;
  }

  .qa-groups-item {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;

    > * {
      display: inline;
    }

    input {
      min-width: 150px;
      margin: 0 !important;
      height: 38px;
      font-size: 20px;
      border: none;
      background-color: var(--neutral-gray);
      padding: 5px 10px 5px 0;
      box-sizing: border-box;
      border-radius: 10px;
      color: var(--font-color);
      margin-bottom: 10px;
      text-align: left;
    }

    input:focus {
      outline: none !important;
    }
  }
  .qa-remove-group {
    height: 30px;
    min-width: 30px;
    box-sizing: border-box;
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(216, 43, 43);
    opacity: 0.6;
    color: #fff;
    border-radius: 15px;
    margin: 0;
    font-size: 50px;
  }

  .qa-remove-group:hover {
    background-color: rgb(216, 43, 43);
    opacity: 1;
    cursor: pointer;
  }
}

.qa-section-header {
  max-width: 270px;
  margin: 20px auto 0 !important;
}

.qa-group-selection {
  padding: 20px 0 20px;
  text-align: center;
  width: 330px;
  margin: 0 auto 0;

  h3 {
    margin: 10px 0 10px;
  }

  div {
    margin: 10px auto 0;
    text-align: center;
  }

  .qa-create-group {
    width: 270px;
    margin: 0 auto 0;

    input {
      width: 250px;
      height: 34px;
      font-size: 20px;
      border: none;
      background-color: var(--neutral-gray);
      padding: 5px 10px 5px 10px;
      border-radius: 10px;
      color: var(--font-color);
      margin-top: 20px;
    }

    input:focus {
      outline: none !important;
    }
  }

  .qa-add-new-group {
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--neutral-gray);
    font-size: 20px;
  }

  .qa-add-new-group:hover,
  .qa-add-group:focus {
    background-color: var(--theme-color);
    cursor: pointer;
    p {
      color: #fff;
    }
  }

  .qa-new-group-type {
    padding: 10px;
    text-align: center;
    font-size: 20px;
    border-radius: 10px;
    opacity: 0.9;
  }

  .qa-new-group-type:hover {
    opacity: 1;
    cursor: pointer;
  }

  .qa-select-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: 35px;
    border-bottom: 2px solid var(--neutral-gray);
    padding: 5px 0 5px 0;
    margin: 0;

    p {
      margin: 0;
      font-size: 20px;
    }
  }

  .qa-select-group:hover {
    cursor: pointer;

    p {
      color: var(--theme-color);
    }
  }

  .qa-close-group-selection {
    margin: 15px auto 0;
    width: 80px;
    text-align: center;
    font-size: 20px;
    background-color: var(--neutral-gray);
    border-radius: 10px;
    padding: 10px;
  }

  .qa-close-group-selection:hover {
    background-color: var(--theme-color);
    cursor: pointer;
    p {
      color: #fff;
    }
  }
}
