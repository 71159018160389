/* Navbar menu icon/button */

.navbar-mobile {
  position: fixed;
  z-index: 399;
  width: 100vw;
  height: 50px;
  bottom: 0;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05);
  background-color: var(--primary-background-color);

  .nav-icon {
    width: 16px;
    height: 16px;
    margin: 0 15px 0 10px;
  }

  .nav-icon-initials {
    font-size: 15px;
    margin: 0 15px 0 15px;
  }

  .change-theme-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 999;
  }
}

.navbar-quick-options-container {
  padding: 0px 3.5vw 0px 3.5vw;
  height: 100%;

  .navbar-quick-options-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  .nav-quick-options-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .nav-quick-options-icon > img {
    margin-top: 5%;
    width: 90%;
    height: 90%;
  }

  .navbar-quick-options-profile {
    border-radius: 50px;
    overflow: hidden;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }
}

.navbar-mobile-search {
  z-index: 300;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0 15px 0;
}

.navbar-quick-options-view {
  position: fixed;
  bottom: 50px;
  width: 100%;
  background-color: var(--primary-background-color);
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.05);
  z-index: 299;
  overflow-y: auto;
  max-height: calc(100vh - 100px);

  .main-menu-container {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;

    > div {
      flex: 1 1 auto;
    }
  }
}
