.profile-pic-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding-top: 1px;
  box-sizing: border-box;
  overflow: hidden;
  transform: translateZ(0);

  span {
    pointer-events: none;
    margin: 0;
    color: #fff !important;
    user-select: none;
    z-index: 1;
  }

  .background-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    mix-blend-mode: soft-light;
  }

  .profile-pic-edit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    color: #fff;
    font-weight: 600;
    background-color: rgba(0,0,0,0.4);
    pointer-events: auto !important;
    opacity: 0;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
