.piechart-widget-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  h3 {
    margin: 0;
  }

  .piechart {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .pie-desc {
    > div {
      margin: 5px;
      font-size: 20px;
    }
  }
}