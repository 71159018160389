.cp-confirm-cancel {
  width: 100%;
  background-color: var(--primary-background-color);
  display: flex;
  justify-content: center;
  gap: 20px;

  > div {
    width: 40px;
    height: 40px;

    &:hover {
      cursor: pointer;
    }

    > img {
      width: inherit;
      height: inherit;
    }
  }
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
  .cp-confirm-cancel {
    padding-bottom: 80px !important;
  }
}
