.ag-container {
  width: 100%;
  padding: 50px 0 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  input {
    margin-bottom: 10px;
    color: var(--font-color);
  }

  .tags-search {
    input {
      width: 100%;
      font-size: 24px;
      border-radius: 10px;
      border: none;
      background-color: var(--neutral-gray);
      padding: 10px;
      box-sizing: border-box;

    }
  }

  .ag-pic {
    margin: 0 auto 0;

    h3 {
      margin-top: 20px;
    }
  }

  .ag-group-create {
    width: min(70%, 300px);
    margin: 0 auto 0;

    > input {
      width: 100%;
      font-size: 24px;
      border-radius: 10px;
      border: none;
      background-color: var(--neutral-gray);
      padding: 10px;
      box-sizing: border-box;
    }

    .ag-submit {
      width: 100%;
      margin: 20px auto 0;
      padding: 10px;
      text-align: center;
      font-size: 24px;
      background-color: var(--theme-color);
      border-radius: 10px;
      box-sizing: border-box;

      &:hover {
        cursor: pointer;
      }

      > span {
        color: #fff;
      }
    }
  }
}