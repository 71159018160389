.timeline-event {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 130px;
  border-radius: 15px;
  overflow: hidden;
  padding: 10px 20px 10px 40px;
  box-sizing: border-box;
  margin-bottom: 10px;
  box-shadow: 2px 2px 3px rgba(0,0,0,0.1);
  z-index: 10;

  &:hover {
    cursor: pointer;

    > .timeline-event-background {
      background-color: var(--neutral-gray) !important;
    }
  }

  
  .timeline-event-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //mix-blend-mode: soft-light;
    //filter: saturate(4) contrast(1.5);
    background-color: var(--timeline-event-background) !important;
  }

  .timeline-event-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: #f0f0f0e0;
  }

  .timeline-event-nub {
    position: absolute;
    top: 0;
    left: 0;
    //filter: brightness(110%);
    width: 25px;
    height: 100%;
    z-index: 10;
  }

  .timeline-event-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    span {
      //color: #000;
      color: var(--font-color);
      opacity: 0.9;
    }

    .timeline-event-type {
      opacity: 0.5;
      white-space: nowrap;

    }
  }

  .timeline-event-time {
    margin-top: auto;
    font-weight: 600;
    white-space: nowrap;
  }


  .timeline-event-name {
    font-size: 26px;
    font-weight: 600;
    opacity: 1;
    white-space: nowrap;   
    z-index: 2;
    //mix-blend-mode: multiply;
  }

  .timeline-event-notes {
    font-size: 16px;
    max-height: 38px;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  .timeline-event-contacts {
    position: relative;
    width: 100px;
    height: 50px;

    .timeline-event-contact {
      position: absolute;
      right: 0;
      width: 50px;
      height: 50px;
      font-size: 25px;
      border-radius: 50px;
      overflow: hidden;
      box-shadow: 2px 2px 3px rgba(0,0,0,0.2);

      &:nth-child(n + 4) {
        display: none;
      }

      .profile-pic-container > span {
        font-size: 25px !important;
      }

      &:hover {
        z-index: 4 !important;
        cursor: pointer;
        box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.5);
        outline: 2px solid rgba(100, 100, 100, 0.3);
      }
    }

    

    .contact-0 {
      right: 0;
      z-index: 1;
    }

    .contact-1 {
      right: 25px;
      z-index: 2;
    }

    .contact-2 {
      right: 50px;
      z-index: 3;
    }
  }

}

@media only screen and (max-width: 400px) {
  .timeline-event-contacts {
    display: none;
  }
}

@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
  .timeline-event {
    height: 85px;
  }

  .timeline-event-notes {
    display: none;
  }

  .timeline-event-nub {
    width: 20px !important;
  }

  .timeline-event-name {
    font-size: 20px !important;
  }

  .timeline-event-type {
    font-size: 16px !important;
  }
}