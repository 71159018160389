.timeline-container {
  max-width: 800px;
  min-width: 280px;
  margin: 0 auto 0;
  display: flex;
  flex-wrap: wrap;

  .timeline {
    width: 100%;
    flex-basis: 1;

  }

  .timeline-options {
    min-width: 100px;
    flex-basis: 1;
  }

  .timeline-date-text {
    margin-bottom: 10px;
    border-bottom: 3px solid var(--light-gray);
    opacity: 0.8;
  }
}

#scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f100;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
}

