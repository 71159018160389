.cp-notes {
  width: 100%;
  overflow: auto;
  resize: none;
  font-size: 20px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: var(--primary-background-color);

  &:focus {
    outline-color: var(--font-color);
  }

  &:read-only {
    &:focus {
      outline: none;
    }
  }
}

.cp-notes-save-cancel {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  gap: 10px;

  > div {
    opacity: 0.9;
    width: 36px;
    height: 36px;
    border-radius: 18px;

    > img {
      width: inherit;
      height: inherit;
    }

    &:hover {
      opacity: 1;
      box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
      cursor: pointer;
    }
  }
}
