.contacts-container {
  display: flex;
  justify-content: space-around;
}

.contact-list-container {
  width: 900px;
  margin: 0 auto 0;
  min-width: 380px;


  h3 {
    font-weight: 500;
  }

  span {
    font-family: "Roboto", sans-serif;
    color: var(--font-color);
  }

  .contact-list-header {
    background-color: var(--primary-background-color);
    border-radius: 10px 10px 0 0;
    height: 50px;
    width: 100%;
    border-bottom: 2px solid var(--neutral-gray);
    padding: 0 30px 0 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    

    > * {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }


    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      flex-basis: 1;
      margin: 0;
    }

    .icon-header {
      min-width: 51px;
      visibility: hidden;
    }
  }

  .contact-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    min-height: 50px;

    h1 {
      margin: 0;
    }
  }

  .contacts-key {
    background-color: var(--primary-background-color);
    padding: 5px;
    border-bottom: 2px solid var(--neutral-gray);
    text-align: center;
    font-size: 18px;
    color: var(--font-color);
  }
}

@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
  .contact-list-container {
    font-size: 14px;
    min-width: 0;

    .group-header{
      margin: 0;
      border-bottom: 2px solid var(--neutral-gray);

    }

    .contact-header {
      padding: 20px 20px 0 20px;
    }

    .contact-list-header {
      gap: 5px;
      padding: 0 5px 0 5px;
      justify-content: space-evenly;
      display: none;

      :nth-child(1) {
        display: none;
      }

      :nth-child(2) {
        min-width: 35px;
        font-size: 18px;
      }

      :nth-child(3) {
        width: 150px;
      }
    }
  }
}

.contacts-button {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 20px;
  z-index: 600;
  background-color: var(--primary-background-color);


  &:hover {
    background-color: var(--neutral-gray);
    box-shadow: 2px 3px 2px rgba(0,0,0,0.1);
  
    > img {
      opacity: 1;
    }
  }

  > img {
    width: inherit;
    height: inherit;
    opacity: 0.5;
  }

}

.contacts-sidebar {
  width: 600px;
  position: fixed;
  right: calc(var(--content-container-padding));
  top: calc(var(--header-height) + 10px);
  height: calc(100vh - var(--header-height) - 20px);
  overflow: hidden;
  border-radius: 15px;

  .cs-expand {
    top: 10px;
    right: 10px;
  }

  .cs-edit {
    top: 10px;
    right: 55px;
  }
}
