.tags-container {
  position: relative;
  color: var(--font-color);

  .tags-search {
    
    > input {
      color: var(--font-color);
    }
  }

  .tags-search-results {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: var(--primary-background-color);
    border: 2px solid var(--neutral-gray);
    box-sizing: border-box;
    z-index: 6;


    .tags-search-result {
      padding: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;

      

      &:hover {
        background-color: var(--light-gray);
        cursor: pointer;
      }
      
    }
  }

  .tags-selected {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .tag-selected {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: var(--neutral-gray);
      padding: 0 10px 0 10px;
      border-radius: 5px;
      font-size: 20px;
      height: 44px;
      color: var(--font-color);
      

      .tag-delete {
        width: 20px;
        height: 20px;
        opacity: 0.7;
        z-index: 5;
        &:hover {
          opacity: 1;
          cursor: pointer;
        }

        > img {
          width: inherit;
          height: inherit;
        }
      }

    }
  }
}