.ae-container {
  --ae-width: min(90vw, 380px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px 0 40px 0;
  box-sizing: border-box;
  color: var(--font-color);


  input {
    background-color: var(--neutral-gray);
    border: none;
    border-radius: 10px;
    color: var(--font-color);
  }

  .ae-name {
    width: var(--ae-width);

    > input {
      width: var(--ae-width);
      padding: 10px;
      border-radius: 10px;
      box-sizing: border-box;
      border: none;
      background-color: var(--neutral-gray);
      font-size: 20px;
    }
  }

  .ae-date {
    display: flex;
    justify-content: space-between;
    width: var(--ae-width);
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;

    .ae-datepicker {
      width: 100%;
    }
  }

  .ae-desc > textarea {
    width: var(--ae-width);
    border-radius: 10px;
    height: 70px;
    resize: none;
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    background-color: var(--neutral-gray);

    
    &:focus {
      outline: none;
    }
  }

  .ae-submit {
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    margin: 0 auto 0;
    text-align: center;
    background-color: var(--theme-color);
    color: #fff;
    font-size: 22px;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }
  }

  .ae-tag {
    width: var(--ae-width);
    font-size: 18px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: var(--neutral-gray);
    border: none;
  }

  .tags-container {
    width: var(--ae-width);

    > * > input {
      width: var(--ae-width);
      font-size: 18px;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 10px;
      background-color: var(--neutral-gray);
      border: none;
      margin-bottom: 10px;
    }
  }
}
/*
.iLXmNF {
  input, span, svg, .dNKQUn::after, .TImBl {
    color: var(--theme-color) !important;
  }
}
*/
