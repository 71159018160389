a {
  text-decoration: none;
}
.contact-card-container {
  .contact-card-item:hover {
    background-color: var(--contact-card-hover);
    cursor: pointer;
  }

  .contact-card-item {
    background-color: var(--primary-background-color);
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0 30px 0 30px;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 2px solid var(--neutral-gray);
    flex-wrap: wrap;
    overflow: hidden;

    > * {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        vertical-align: middle;
      }
    }

    .contact-card-checkbox {
      margin: 0;
      min-width: 16px;
      height: 16px;
    }

    .contact-card-profile-picture {
      height: 51px;
      min-width: 51px;
      border-radius: 50px;
      overflow: hidden;
      font-size: 21px;
    }
  }
}

@media only screen and (max-width: 600px),
       only screen and (max-height: 600px) {
  .contact-card-container {
    .contact-card-item {
      gap: 10px;
      padding: 0 15px 0 15px;
      height: 50px;
      justify-content: space-evenly;

      .contact-card-checkbox {
        width: 30px;
        display: none;
      }
      
      .attr-container {
        height: inherit;
      }

      .attr-birthday, .attr-goal-status {
        display: none;
      }

      .contact-card-profile-picture {
        min-width: 35px;
        width: 36px;
        height: 36px;
        > * > span {
          font-size: 18px !important;
        }
      }

      .attr-name {
        min-width: 100px;
        margin-right: auto;
      } 

      .contact-card-date {
        width: 70px;
      }

      .contact-card-days {
        width: 50px;
      }
    }
  }
}

