.ai-container {
  padding: 50px 0 50px;
  width: min(80%, 95vw);
  margin: 0 auto 0;

  input {
  background-color: var(--neutral-gray);
  border: none;
  border-radius: 10px;
  color: var(--font-color);
}


  .ai-interactions-flex {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 15px;

    > h2 {
      width: 100%;
      text-align: center;
    }

    textarea {
      resize: none;
      width: 70%;
      border-radius: 10px;
      font-size: 18px;
      border: 2px solid var(--light-gray);
      padding: 3px;
      box-sizing: border-box;
    }
  }

  .ai-interactions-type {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 60px;
    height: 50px;
    border-radius: 10px;
    border: 2px solid var(--light-gray);
    background-color: var(--primary-background-color);
    //box-shadow: 0 0 10px  rgba(0,0,0,0.1);

    &:hover {
      background-color: var(--neutral-gray);
      cursor: pointer;
    }
    .ai-interactions-type-img {
      width: 30px;
    }
    .ai-interactions-dropdown {
      width: 10px;
      transform: rotate(-90deg);
    }
  }

  .ai-interactions-select-type {
    position: absolute;
    top: -3px;
    left: -3px;
    background-color: var(--primary-background-color);
    width: max(280px, 60%);
    z-index: 50;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid var(--light-gray);

    .ai-interactions-dropdown-type {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      gap: 10px;
      padding: 10px;
      box-sizing: border-box;

      > img {
        max-height: 30px;
        max-width: 30px;
      }

      > span {
        font-size: 20px;
        color: var(--font-color);
      }

      &:hover {
        background-color: var(--neutral-gray);
      }
    }
  }

  .ai-interactions-dtp {
    background-color: var(--neutral-gray);
    box-sizing: border-box;
    border-radius: 50px;
    width: 80%;
    margin: 0 auto 0;
    //background-color: var(--theme-color);
  }

  .tags-container {
    width: 80%;
    input {
      width: 100%;
      box-sizing: border-box;
      font-size: 18px;
      padding: 9px;
      border-radius: 10px;
      margin: 0 auto 0;
      margin-bottom: 10px;
    }
  }

  .ai-submit {
    width: 80%;
    padding: 10px;
    background-color: var(--theme-color);
    color: #fff;
    font-size: 20px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 10px;

    &:hover {
      cursor: pointer;
    }
  }

}