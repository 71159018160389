.widget-container {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background-color);
  color: var(--font-color);
  margin: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
  box-sizing: border-box;
  height: calc(100% - 20px);
  overflow: hidden;


  * {
    user-select: none;
  }
  > div {
    flex-grow: 1;
  }
  h3 {
    margin: 10px 10px 10px 10px;
  }
}

.dashboard-message {
  color: var(--font-color);
  background-color: var(--primary-background-color);
  padding-top: 40px;
  padding-left: 30px;
  height: 90px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.widget-contact-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px 10px 20px;
  gap: 10px;
  font-size: 18px;
  background-color: var(--primary-background-color);

  &:hover {
    cursor: pointer;
    background-color: var(--neutral-gray);
  }
  p {
    z-index: 10;
  }
}

.widget-title {
  //margin-bottom: 3%;
}

.widget-date {
  //margin-left: 70%;
  //margin-top: -4.3%;
  font-weight: lighter;
}