.ib-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5000;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background-color: rgba(100,100,100,0.3);

  .ib-main {
    position: relative;
    background-color: var(--primary-background-color);
    border: 2px solid var(--secondary-background-color);
    border-radius: 15px;
    box-shadow: 0 10px 5px rgba(0,0,0,0.1);

    display: flex;
    align-items: center;
    //overflow: auto;

    .ib-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      outline: none;
      border: none;
      z-index: 100;

      > img {
        width: inherit;
        height: inherit;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
}