.cp-ae-event-card {
  //border-bottom: 3px solid var(--light-gray);
  width: 90%;
  margin: 0 auto 0;
  box-sizing: border-box;
  color: var(--font-color);
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  gap: 10px;
  border: 1px solid var(--light-gray);
  margin-bottom: 5px;
  box-shadow: 2px 2px var(--neutral-gray);
  padding-right: 20px;

  &:hover {
    background-color: var(--contact-card-hover);
    cursor: pointer;
  }

  .cp-ae-event-card-nub {
    width: 10px;
    height: 40px;
  }

  .cp-ae-event-time {
    margin-left: auto;
  }
}