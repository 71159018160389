.cp-interaction-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  min-height: 40px;
  padding: 10px;
  border-bottom: 2px solid var(--light-gray);
  box-sizing: border-box;
  
  .type {
    max-width: 30px;
    height: 30px;
    > img {
      max-width: 30px;
      height: 30px;

    }
  }

  .date {
    min-width: 60px;
  }

  .notes {
    flex-grow: 1;
    overflow-wrap: break-word;
    overflow-x: hidden;
    padding: 5px;
    text-align: left;
  }
}
