.cp-ae-container {
  padding-bottom: 10px;

  .cp-ae-add-event {
    p {
      margin: 10px auto 5px;
    }

    .cp-event-dtp {
      width: 80%;
      margin: 0 auto 0;
      border: 2px solid var(--theme-color);
      border-radius: 30px;
    }
  }

  .cp-ae-title {
    background-color: var(--primary-background-color);
    color: var(--font-color);
    width: 80%;
    font-size: 20px;
    border-radius: 10px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #aaa;
    margin-top: 10px;
  }

  .cp-ae-notes {
    width: 80%;
    resize: none;
    border-radius: 10px;
    color: var(--font-color);
    font-size: 18px;
    margin-top: 15px;
    border-color: #aaa;
  }

  .cp-ae-new-event {
    width: 80%;
    margin: 10px auto 10px;
    border: 2px solid var(--theme-color);
    padding: 8px;
    border-radius: 20px;
    box-sizing: border-box;

    > span {
      color: var(--theme-color);
      font-size: 18px;
      font-weight: 600;
    }

    &:hover {
      background-color: var(--theme-color);
      cursor: pointer;
      > span {
        color: #fff;
      }
    }
  }

  .cp-ae-events > p {
    margin: 15px;
  }

  .cp-ae-date {
    position: relative;
    width: 90%;
    margin: 15px auto 4px;
    height: 25px;

    .cp-ae-date-line {
      pointer-events: none;

      z-index: 0;
      height: calc(50% - 2px);
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 3px solid var(--light-gray);
      box-shadow: 2px 3px 4px  -3px var(--neutral-gray);
    }

    .cp-ae-date-text {
      position: absolute;
      background-color: var(--primary-background-color);
      z-index: 10;
      border-radius: 15px;
      border: 3px solid var(--light-gray);
      box-sizing: border-box;
      width: 130px;
      font-size: 16px;
      left: calc(50% - 65px);
      box-shadow: 1px 2px 2px var(--neutral-gray);

    }
  }
}