.options-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;

  &:hover {
    background-color: var(--neutral-gray);
    cursor: pointer;
  }

  > img {
    user-select: none;
    width: 70%;
    height: 70%;
    opacity: 0.6;
  }


}
.options-container {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 200px;
  background-color: var(--primary-background-color);
  border: 2px solid var(--neutral-gray);
  user-select: none;
  border-radius: 5px;
  z-index: 10;

  .option {
    padding: 12px;
    text-align: left;
    font-weight: 600;
    color: var(--font-color);

    &:hover {
      background-color: var(--neutral-gray);
      cursor: pointer;
    }
  }

  .options-delete {
    color: rgb(253, 84, 84);

    &:hover {
      background-color: rgb(253, 84, 84);

      > span {
        color: #fff !important;
      }
    }
  }
}


.options-delete-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  padding: 30px 0 30px;
  
  > h3 {
    font-weight: normal;
  }

  .options-delete-option {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .options-dialog-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;

    > div {
      width: 120px;
      padding: 10px;
      background-color: var(--light-gray);
      color: var(--font-color);
      border-radius: 10px;

      &:hover {
        cursor: pointer;
        background-color: var(--neutral-gray);
      }
    }

    .options-delete-button {
      background-color: rgb(233, 23, 23) !important;
      
      > span {
        color: #fff !important;
      }

      &:hover {
        background-color: rgb(192, 25, 25) !important;
      }
    }
  }



}