.kit-container {
  min-width: 1400px;
  max-width: 1400px;
  color: var(--font-color);

  margin: 0 auto 0;
  background-color: var(--primary-background-color);
  padding: 20px 40px 20px 40px;
  border-radius: 20px;

  > h1 {
    margin-bottom: 20px;
  }

  .kit-dnd {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
  }

  .kit-goal-list {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    max-width: 200px;
    padding-top: 10px;

    &:nth-child(odd) {
      background-color: rgba(175, 175, 175, 0.075);
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);

    }

    &:nth-child(even) {
      background-color: rgba(58, 58, 58, 0.096);
      
    }
  }

  .kit-goal-droppable {
    border-radius: 5px;
    height: 100%;
  }

  .kit-group-selected {
    position: relative;
    width: min(90%, 300px);
    height: 50px;
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 2px solid var(--light-gray);
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 1px 1px 2px var(--neutral-gray);

    &:hover {
      background-color: var(--neutral-gray);
      cursor: pointer;
    }
  }

  .kit-group-list {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 300px;
    background-color: var(--primary-background-color);
    border: 2px solid var(--light-gray);
    border-radius: 5px;
    box-shadow: 3px 3px 3px var(--neutral-gray);
    z-index: 50;

    .kit-group-item {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 10px;
      height: 30px;

      &:hover {
        background-color: var(--light-gray);
      }
    }
  }
}