.cp-box-container {
  width: var(--input-width);
  margin: 0 auto 50px;
  box-shadow: 0px 3px 10px -5px rgba(0,0,0,0.3);
  border: 1px solid var(--light-gray);
  border-radius: 10px;
  overflow: hidden;

  .cp-box-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cp-box-tab {
    width: 33%;
    padding: 8px;
    height: 22px;
    border-bottom: 3px solid var(--light-gray);
    text-align: center;

    > span {
      font-size: 18px;
      color: var(--font-color);
    }

    &:hover {
      background-color: var(--light-gray);
      

      cursor: pointer;
    }
  }

  .cp-box-active {
    border-bottom: 3px solid var(--theme-color);
    //height: 19px;
  }

  .cp-box-content {
    //border-top: 3px solid var(--neutral-gray);
    //border-bottom: 3px solid var(--neutral-gray);

  }
}

@media only screen and (max-width: 600px),
  only screen and (max-height: 600px) {
  .cp-box-container {
    width: 100%;
  }
}