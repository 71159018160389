.cp-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  min-width: 280px;
  max-width: 1400px;
  margin: 0 auto 0px;
  --input-width: max(260px, 80%);
  border-radius: 10px;
  overflow: hidden;
  

  & > div {
    padding: 20px 0 20px;
    background-color: var(--primary-background-color);
    border-radius: 0;
    flex-grow: 1;
  }

  input[type="button"] {
    border: none;
  }

  input[type="text"]:read-write {
    width: var(--input-width);
    height: 38px;
    font-size: 20px;
    border: none;
    //border: 2px solid var(--neutral-gray);
    //box-shadow: inset 0 10px 25px var(--neutral-gray);
    background-color: var(--neutral-gray);
    //background-color: var(--primary-background-color);
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--font-color);
    margin-bottom: 10px;
  }

  input[type="text"]:read-only {
    width: var(--input-width);
    height: 38px;
    font-size: 20px;
    border: none;
    background: none;
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--font-color);
    white-space: nowrap;
    text-align: center;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  div {
    text-align: center;
  }

  .cp-field-label {
    margin-bottom: 10px;
  }

  .cp-header {
    width: 100%;
  }

  .cp-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 20px 20px 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    overflow: hidden;

    h1 {
      width: 100%;
    }
  }

  .cp-main {
    position: relative;
    width: 30%;
    min-width: 300px;
    height: inherit;
    box-sizing: border-box;

    .cp-name {
      width: 100%;

      > h3 {
        width: var(--input-width);
        margin: 0 auto 10px;
      }
    }

    .cp-birthday {
      position: relative;
      margin: 0 auto 0;
      width: var(--input-width);

      .birthday-placeholder {
        @extend input, :read-write;
        position: absolute;

        &:focus {
          outline: none;
        }
      }

      input[type='checkbox'] {
        height: 20px;
        width: 20px;
      }
    }

    .cp-list {
      width: var(--input-width);
      margin: 0 auto 0;

      input {
        float: left;
        width: 100%;
      }

      &:last-child {
        input {
          width: calc(100% - 45px);
        }
      }

      .cp-add-entry {
        float: right;
        width: 38px;
        height: 38px;
        background-color: var(--neutral-gray);
        border-radius: 19px;
        color: var(--font-color);
        line-height: 38px;
        font-size: 30px;
        opacity: 0.6;
      }

      .cp-add-entry:hover,
      .cp-add-entry:focus {
        background-color: var(--theme-color);
        color: #fff;
        cursor: pointer;
        opacity: 1;
      }

      .cp-list-entry,
      .cp-list-entry {
        position: relative;

        .cp-phone-icon {
          height: 28px;
          width: 28px;
          position: absolute;
          left: 0;
          top: 5px;
          background-color: transparent;
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='477.156px' height='477.156px' viewBox='0 0 477.156 477.156' style='enable-background:new 0 0 477.156 477.156;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M475.009,380.316l-2.375-7.156c-5.625-16.719-24.062-34.156-41-38.75l-62.688-17.125c-17-4.625-41.25,1.594-53.688,14.031 l-22.688,22.688c-82.453-22.28-147.109-86.938-169.359-169.375L145.9,161.94c12.438-12.438,18.656-36.656,14.031-53.656 l-17.094-62.719c-4.625-16.969-22.094-35.406-38.781-40.969L96.9,2.19c-16.719-5.563-40.563,0.063-53,12.5L9.962,48.659 C3.899,54.69,0.024,71.94,0.024,72.003c-1.187,107.75,41.063,211.562,117.281,287.781 c76.031,76.031,179.454,118.219,286.891,117.313c0.562,0,18.312-3.813,24.375-9.845l33.938-33.938 C474.946,420.878,480.571,397.035,475.009,380.316z' fill='%23999'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
          background-position: center;
          background-size: 28px 28px;
          cursor: pointer;
          opacity: 0.8;
          filter: drop-shadow(2px 2px 2px rgba(153, 153, 153, 0.363));

          &:hover {
            opacity: 1;
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='477.156px' height='477.156px' viewBox='0 0 477.156 477.156' style='enable-background:new 0 0 477.156 477.156;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M475.009,380.316l-2.375-7.156c-5.625-16.719-24.062-34.156-41-38.75l-62.688-17.125c-17-4.625-41.25,1.594-53.688,14.031 l-22.688,22.688c-82.453-22.28-147.109-86.938-169.359-169.375L145.9,161.94c12.438-12.438,18.656-36.656,14.031-53.656 l-17.094-62.719c-4.625-16.969-22.094-35.406-38.781-40.969L96.9,2.19c-16.719-5.563-40.563,0.063-53,12.5L9.962,48.659 C3.899,54.69,0.024,71.94,0.024,72.003c-1.187,107.75,41.063,211.562,117.281,287.781 c76.031,76.031,179.454,118.219,286.891,117.313c0.562,0,18.312-3.813,24.375-9.845l33.938-33.938 C474.946,420.878,480.571,397.035,475.009,380.316z' fill='%230a9943'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
          }
        }

        .cp-sms-icon {
          height: 28px;
          width: 28px;
          position: absolute;
          left: 40px;
          top: 5px;
          background-color: transparent;
          background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Esms%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Rounded' transform='translate(-510.000000, -3700.000000)'%3E%3Cg id='Notification' transform='translate(100.000000, 3600.000000)'%3E%3Cg id='-Round-/-Notification-/-sms' transform='translate(408.000000, 98.000000)'%3E%3Cg%3E%3Cpolygon id='Path' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M9,11 L7,11 L7,9 L9,9 L9,11 Z M13,11 L11,11 L11,9 L13,9 L13,11 Z M17,11 L15,11 L15,9 L17,9 L17,11 Z' id='🔹-Icon-Color' fill='%23999'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-position: center;
          background-size: 28px 28px;
          cursor: pointer;
          opacity: 0.8;
          filter: drop-shadow(2px 2px 2px rgba(153, 153, 153, 0.363));


          &:hover {
            opacity: 1;
            background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Esms%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Rounded' transform='translate(-510.000000, -3700.000000)'%3E%3Cg id='Notification' transform='translate(100.000000, 3600.000000)'%3E%3Cg id='-Round-/-Notification-/-sms' transform='translate(408.000000, 98.000000)'%3E%3Cg%3E%3Cpolygon id='Path' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M9,11 L7,11 L7,9 L9,9 L9,11 Z M13,11 L11,11 L11,9 L13,9 L13,11 Z M17,11 L15,11 L15,9 L17,9 L17,11 Z' id='🔹-Icon-Color' fill='%230a9943'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }

        .cp-email-icon {
          height: 28px;
          width: 28px;
          position: absolute;
          left: 0;
          top: 5px;
          background-color: transparent;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z' fill='%23999'/%3E%3C/svg%3E");
          background-position: center;
          background-size: 28px 28px;
          background-repeat: no-repeat;
          cursor: pointer;
          opacity: 0.9;
          filter: drop-shadow(2px 2px 2px rgba(153, 153, 153, 0.363));


          &:hover {
            opacity: 1;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z' fill='%230a9943'/%3E%3C/svg%3E");
          }
        }
      }
    }

    .cp-add-group {
      width: 160px;
      margin: 30px auto 30px;
      text-align: left;

      > * {
        display: inline;
      }

      h3 {
        width: 50%;
        font-weight: 300;
        font-size: 22px;
        line-height: 40px;
      }

      .cp-add-group {
        font-size: 20px;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background-color: var(--neutral-gray);
        opacity: 0.6;
        float: right;
        line-height: 40px;
        font-size: 35px;
        cursor: pointer;
        color: var(--font-color);
      }

      .cp-add-group:hover,
      .cp-add-group:focus {
        opacity: 1;
        background-color: var(--theme-color);
        color: #fff;
        outline: none;
      }
    }

    .tags-selected {
      width: var(--input-width) !important;
      margin: 0 auto 0;
    }

    .tags-search-results {
      width: var(--input-width) !important;
      left: calc((100% - var(--input-width))/2);
    }

    .cp-box {
      background-color: rgba(100, 100, 100, 0.1);
      //background-color: var(--primary-background-color);
      box-shadow: 2px 3px 3px rgba(0,0,0,0.1);
      border-radius: 10px;
      margin: 0 auto 10px;
      padding: 10px;

      h3 {
        margin-top: 5px;
      }
    }

    .cp-groups {
      width: max(80%, 260px);
      margin: 0px auto 0px;

      text-align: center;

      //background-color: rgba(100, 100, 100, 0.1);
      // border-radius: 20px;
      //padding: 10px;
      box-sizing: border-box;

      h3 {
        //margin: 15px 0 15px;
        // font-size: 21px;
      }
      .cp-groups-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .cp-groups-item {
        text-align: center;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        margin: 5px;
        background-color: var(--neutral-gray);
        padding: 10px 15px 10px 15px;
        border-radius: 7px;

        &:hover {
          background-color: var(--contact-card-hover);
          cursor: pointer;
        }

        > * {
          display: inline;
        }

        .cp-group-name {
          color: var(--font-color);
          font-size: 18px;
          margin-left: 10px;
        }

        input:focus {
          outline: none !important;
        }
      }
      .cp-remove-group {
        height: 30px;
        min-width: 30px;
        box-sizing: border-box;
        line-height: 28px;
        text-align: center;
        vertical-align: middle;
        background-color: rgb(216, 43, 43);
        opacity: 0.6;
        color: #fff;
        border-radius: 15px;
        margin: 0;
        font-size: 50px;
        margin-left: auto;
      }

      .cp-remove-group:hover {
        background-color: rgb(216, 43, 43);
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .cp-secondary {
    width: min(500px, 100%);
    height: inherit;
    flex-grow: 2;
  }

  .cp-field {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 20px;
    margin-top: 20px;

    span::after {
      content: ":";
      margin-right: 10px;
    }

    input {
      font-size: 20px;
      width: 50%;
    }
  }
}

.cp-group-selection {
  padding: 20px 0 20px;
  text-align: center;

  h3 {
    margin: 30px 0 10px;
  }

  div {
    margin: 10px auto 10px;
    text-align: center;
    box-sizing: border-box;
  }

  .cp-create-group {
    width: max(60%, 220px);
    margin: 0 auto 0;

    input {
      width: 100%;
      height: 44px;
      font-size: 20px;
      border: none;
      background-color: var(--neutral-gray);
      padding: 10px;
      border-radius: 10px;
      color: var(--font-color);
      margin: 20px 0 0 0;
    }

    input:focus {
      outline: none !important;
    }

    .profile-pic-container {
      box-shadow: 0 0 20px rgba(160, 160, 160, 0.3);
    }
  }

  .cp-add-new-group {
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--neutral-gray);
    font-size: 20px;
  }

  .cp-add-new-group:hover,
  .cp-add-group:focus {
    background-color: var(--theme-color);
    cursor: pointer;
    p {
      color: #fff;
    }
  }

  .cp-new-group-type {
    padding: 10px;
    text-align: center;
    font-size: 20px;
    border-radius: 10px;
    opacity: 0.9;
  }

  .cp-new-group-type:hover {
    opacity: 1;
    cursor: pointer;
  }

  .cp-select-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: 35px;
    border-bottom: 2px solid var(--neutral-gray);
    padding: 5px 0 5px 0;
    margin: 0;

    p {
      margin: 0;
      font-size: 20px;
    }
  }

  .cp-select-group:hover {
    cursor: pointer;

    p {
      color: var(--theme-color);
    }
  }

  .cp-close-group-selection {
    margin: 15px auto 0;
    width: 80px;
    text-align: center;
    font-size: 20px;
    background-color: var(--neutral-gray);
    border-radius: 10px;
    padding: 10px;
  }

  .cp-close-group-selection:hover {
    background-color: var(--theme-color);
    cursor: pointer;
    p {
      color: #fff;
    }
  }
}

@media only screen and (max-width: 600px),
  screen and (max-height: 600px),
  (hover: not hover) {
  .cp-header {

    .profile-pic-container {
      flex-basis: 1;
      width: 100px !important;
      height: 100px !important;
      > span {
        font-size: 50px !important;
      }
    }

    h1 {
      font-size: 30px;
      width: 100%;
    }
  }

  .cp-box {
    width: 90% !important;
    /*
    & > .cp-phone-entry > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      .cp-phone-icon,
      .cp-sms-icon {
        max-width: 28px;
        min-width: 28px;
        position: static !important;
        flex: auto;
      }
    }*/

    & > .cp-list-entry > div {
      .cp-phone-icon {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='477.156px' height='477.156px' viewBox='0 0 477.156 477.156' style='enable-background:new 0 0 477.156 477.156;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M475.009,380.316l-2.375-7.156c-5.625-16.719-24.062-34.156-41-38.75l-62.688-17.125c-17-4.625-41.25,1.594-53.688,14.031 l-22.688,22.688c-82.453-22.28-147.109-86.938-169.359-169.375L145.9,161.94c12.438-12.438,18.656-36.656,14.031-53.656 l-17.094-62.719c-4.625-16.969-22.094-35.406-38.781-40.969L96.9,2.19c-16.719-5.563-40.563,0.063-53,12.5L9.962,48.659 C3.899,54.69,0.024,71.94,0.024,72.003c-1.187,107.75,41.063,211.562,117.281,287.781 c76.031,76.031,179.454,118.219,286.891,117.313c0.562,0,18.312-3.813,24.375-9.845l33.938-33.938 C474.946,420.878,480.571,397.035,475.009,380.316z' fill='%230a9943'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important;
        background-repeat: no-repeat;
        left: calc(100% - 70px) !important;
        opacity: 0.8;
      }
      .cp-sms-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Esms%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Rounded' transform='translate(-510.000000, -3700.000000)'%3E%3Cg id='Notification' transform='translate(100.000000, 3600.000000)'%3E%3Cg id='-Round-/-Notification-/-sms' transform='translate(408.000000, 98.000000)'%3E%3Cg%3E%3Cpolygon id='Path' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M9,11 L7,11 L7,9 L9,9 L9,11 Z M13,11 L11,11 L11,9 L13,9 L13,11 Z M17,11 L15,11 L15,9 L17,9 L17,11 Z' id='🔹-Icon-Color' fill='%230a9943'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
        background-repeat: no-repeat;
        left: calc(100% - 28px) !important;
        opacity: 0.8 !important;
      }
      .cp-email-icon {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512px' height='512px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M448,64H64C28.656,64,0,92.656,0,128v256c0,35.344,28.656,64,64,64h384c35.344,0,64-28.656,64-64V128 C512,92.656,483.344,64,448,64z M342.656,234.781l135.469-116.094c0.938,3,1.875,6,1.875,9.313v256 c0,2.219-0.844,4.188-1.281,6.281L342.656,234.781z M448,96c2.125,0,4,0.813,6,1.219L256,266.938L58,97.219 C60,96.813,61.875,96,64,96H448z M33.266,390.25C32.828,388.156,32,386.219,32,384V128c0-3.313,0.953-6.313,1.891-9.313 L169.313,234.75L33.266,390.25z M64,416c-3.234,0-6.172-0.938-9.125-1.844l138.75-158.563l51.969,44.531 C248.578,302.719,252.297,304,256,304s7.422-1.281,10.406-3.875l51.969-44.531l138.75,158.563C454.188,415.062,451.25,416,448,416 H64z' fill='%230a9943'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important;
        left: calc(100% - 36px) !important;
        opacity: 0.8 !important;
      }
      input[type="text"] {
        width: calc(100% - 100px) !important;
        text-align: left;
        padding-left: 1vw;
      }
      .cp-email-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z' fill='%230a9943'/%3E%3C/svg%3E") !important;
        left: calc(100% - 28px) !important;
        opacity: 0.8 !important;
      }

      input[name="email"] {
        width: calc(100% - 36px) !important;
        text-align: left;
        padding-left: 1vw;
      }
    }
  }
}
