.kit-card-container {
  position: relative;
  background-color: var(--primary-background-color);
  //background-color: rgb(29, 29, 29);
  color: var(--font-color);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  border: 1px solid var(--light-gray);
  gap: 10px;
  overflow: hidden;

  

  .kit-card-goal-status {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-pic-status {
    min-width: 36px;
    min-height: 36px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.247), rgba(49, 49, 49, 0.363));

    border: 1px solid rgba(185, 185, 185, 0.589);

  }
}