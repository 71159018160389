.sm-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 0 20px 20px 20px;
  width: var(--input-width);
  margin: 0 auto 0;

  .sm-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: var(--neutral-gray);      
    border-radius: 25px;

    &:hover {
      cursor: pointer;
      background-color: var(--light-gray);
      box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.1);
    }    

    > img {
      vertical-align: middle;
      width: 75%;
    }
  }
}

