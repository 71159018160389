@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500&display=swap");

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
select,
text {
  color: var(--font-color);
  margin: 0;
}

input[type="text"] {
  color: var(--font-color);
}

body,
textarea,
select,
text,
input[type="text"] {
  font-family: "Roboto", sans-serif;
}

#app-title,
#app-title > * {
  font-family: "M PLUS Rounded 1c", sans-serif;
}

#app-title span {
  color: #999;
}
