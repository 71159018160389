.timeline-header {
  width: 100%;
  position: relative;
  color: var(--font-color);
  margin-bottom: 10px;


  .timeline-dropdown-open, .timeline-type-button {
    background-color: var(--primary-background-color);
    padding: 10px;
    font-size: 20px;
    width: min(50%, 200px);
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);

    &:hover {
      background-color: var(--light-gray);
      cursor: pointer;
    }
  }

  .timeline-type-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;

    > div {
      border-radius: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .timeline-header {
    padding: 10px;

    .timeline-type-dropdown {
      width: 100%;
      box-sizing: border-box;

      > * {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}
